.section
  position: relative
  max-width: 600px
  width: 90%
  margin: 0 auto

.form
  display: flex
  flex-direction: column
  gap: 18px

.formInput
  position: relative
  & label
    display: flex
    flex-direction: column
    gap: 8px
    & span
      display: block
      font-size: 14px
      line-height: 1
      font-weight: 600
    & input
      font-size: 16px
      line-height: 1
      font-weight: 400
      padding: 4px 8px


.button
  font-size: 16px
  font-weight: 600
  line-height: 1
  padding: 16px 32px
  background-color: #eeeeee
  border: 1px solid #000000
  border-radius: 8px
  max-width: 200px
  margin-left: auto
  margin-right: auto
  &:hover
    cursor: pointer

.answer
  margin-top: 100px
  white-space: pre-line

.alert
  font-size: 16px
  line-height: 1.4
  font-weight: 600
  color: #ffffff
  text-align: center
  margin-bottom: 16px
  border: rgba(255, 0, 0, 1)
  border-radius: 4px
  background-color: rgba(255, 0, 0, .5)
  padding: 8px

.loader
  margin: 0 auto
  width: 60px
  aspect-ratio: 4
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000)
  background: var(--_g) 0%  50%, var(--_g) 50%  50%, var(--_g) 100% 50%
  background-size: calc(100%/3) 100%
  animation: l7 1s infinite linear

@keyframes l7
  33%
    background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
  50%
    background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
  66%
    background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
